exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-musings-index-js": () => import("./../../../src/pages/blog/musings/index.js" /* webpackChunkName: "component---src-pages-blog-musings-index-js" */),
  "component---src-pages-blog-musings-mdx-frontmatter-slug-js-content-file-path-content-musings-gpt给-shameless写的诗歌-md": () => import("./../../../src/pages/blog/musings/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/musings/GPT给Shameless写的诗歌.md" /* webpackChunkName: "component---src-pages-blog-musings-mdx-frontmatter-slug-js-content-file-path-content-musings-gpt给-shameless写的诗歌-md" */),
  "component---src-pages-blog-musings-mdx-frontmatter-slug-js-content-file-path-content-musings-照片与代码显示测试-md": () => import("./../../../src/pages/blog/musings/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/musings/照片与代码显示测试.md" /* webpackChunkName: "component---src-pages-blog-musings-mdx-frontmatter-slug-js-content-file-path-content-musings-照片与代码显示测试-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

